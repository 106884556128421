








































































































import { Component, Vue } from 'vue-property-decorator';
import { Saque, Auth } from '@/plugins/api';
import PageTitle from '@/layouts/components/PageTitle.vue';

@Component({
  components: {
    PageTitle,
  },
})
export default class Topbar extends Vue {
  public interval: NodeJS.Timeout | null = null;
  public limiteSaques = 5;
  public saques = [] as ISaque[];
  public quantidade = 0;
  public verticalCollapsed = false;

  created(): void {
    const body = document.body;
    const verticalCollapsed = localStorage.getItem('vertical-collapsed');
    if (verticalCollapsed === null) {
      localStorage.setItem('vertical-collapsed', 'false');
    } else {
      this.verticalCollapsed = verticalCollapsed === 'true';
    }
    if (this.verticalCollapsed) {
      body.classList.add('vertical-collapsed');
    } else {
      body.classList.remove('vertical-collapsed');
    }
  }

  mounted(): void {
    this.updateNotification();
  }

  toogleSidebar(): void {
    const body = document.body;
    body.classList.toggle('sidebar-enable');
    body.classList.toggle('vertical-collapsed');

    const contains = body.classList.contains('vertical-collapsed');
    localStorage.setItem('vertical-collapsed', contains ? 'true' : 'false');
  }

  updateNotification(): void {
    if (Auth.instance.isLogged) {
      const request = {
        params: {
          sortBy: 'data_solicitacao',
          sortDesc: 'DESC',
          size: this.limiteSaques,
          filters: [
            {
              field: 'saques.situacao',
              operator: 'eq',
              value: 'pendente',
            },
          ],
        },
      };
      Saque.get(request).then((response: GetSaquesResponse) => {
        this.saques = response.data;
        this.quantidade = response.metadata.total;
        if (this.$store.state.sidebar['/saques'] !== this.quantidade) {
          this.$store.commit('SET_SIDEBAR_BADGE', {
            id: '/saques',
            value: response.metadata.total,
          });
        }
      });
    }
  }

  getImagePerfil(url: string): string {
    if (url === undefined) {
      url = '/painel/images/perfil/sem-imagem.svg';
    }
    return '//dashboard.parceirosproteauto.com.br' + url;
  }

  logout(): void {
    Auth.instance.logout();
    this.$router.push('/login?loggedOut');
  }
}

import Vue from 'vue';
import i18n from '@/i18n';

function SaqueSituacao(value: string) {
  switch (value) {
    case 'rejeitado':
      return i18n.t('saques.situacoes.rejeitado');
    case 'pendente':
      return i18n.t('saques.situacoes.pendente');
    case 'pago':
      return i18n.t('saques.situacoes.pago');
    default:
      return '-';
  }
}

function SaqueCor(value: string, prefix = '') {
  switch (value) {
    case 'rejeitado':
      return `${prefix}danger`;
    case 'pendente':
      return `${prefix}warning`;
    case 'pago':
    default:
      return 'light';
  }
}

function SaqueBadge(value: string, prefix = '') {
  switch (value) {
    case 'rejeitado':
      return `${prefix}danger`;
    case 'pendente':
      return `${prefix}warning`;
    case 'pago':
    default:
      return `${prefix}soft-secondary`;
  }
}

Vue.filter('SaqueSituacao', SaqueSituacao);
Vue.filter('SaqueCor', SaqueCor);
Vue.filter('SaqueBadge', SaqueBadge);

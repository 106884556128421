






















import { Component, Vue, Ref } from 'vue-property-decorator';
import ContextFavorecido from '@/components/ContextOptions/ContextFavorecido.vue';
import ContextIndicador from '@/components/ContextOptions/ContextIndicador.vue';
import ExcluirSaque from './ExcluirSaque.vue';
import { Saque } from '@/plugins/api';
import VueContext from 'vue-context';

@Component({
  components: {
    ContextFavorecido,
    ContextIndicador,
    ExcluirSaque,
  },
})
export default class SaquesContext extends Vue {
  @Ref() readonly menu!: VueContext;

  public open(event: MouseEvent, item: ISaque): void {
    this.menu.open(event, item);
  }

  public refresh(): void {
    this.close();
    this.$emit('refresh');
  }

  public close(): void {
    this.menu.close();
    this.$emit('close');
  }

  public criarSaqueComissao(id: number): void {
    this.close();

    Saque.excluir(id)
      .then(() => {
        this.$emit('refresh');
        this.$swal({
          icon: 'success',
          title: 'Saque Apagado',
        });
      })
      .catch((err) => {
        this.$swal({
          icon: 'error',
          title: err.response.data,
        });
      });
  }
}
